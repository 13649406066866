<template>
  <div>
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>导入管理</BreadcrumbItem>
          <BreadcrumbItem>导入业绩</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <div class="import-m">
      <Spin size="small" fix v-if="loading"></Spin>
      <Spin fix v-if="isShowPercent">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>上传中</div>
      </Spin>
      <div class="error-msg" v-if="errorMsg != ''">{{ errorMsg }} <a href="javascript:void(0);" @click="opentags('user_level')"> 前往会员等级>></a></div>
      <div class="edit-m">
        <Form label-position="left" :label-width="0" ref="importRef" :model="importParam" :rules="ruleValid">
          <FormItem label="">
            <Divider dashed><span style="color:#999;font-size: 14px;">平台类型</span></Divider>
            <RadioGroup v-model="importParam.platform_type" type="button" button-style="solid" @on-change="selchange('platform_type')">
              <Radio :label="p.id" v-for="(p,k) in platform" :key="k">{{p.platform}}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="">
            <Divider dashed><span style="color:#999;font-size: 14px;">结算方式</span></Divider>
            <RadioGroup v-model="importParam.settlement_type" type="button" button-style="solid" @on-change="selchange('settlement_type')">
              <Radio :label="1">不计入业绩</Radio>
              <Radio :label="2">计入业绩</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="" v-if="importParam.settlement_type && importParam.platform_type" prop="reward_type">
            <Divider dashed><span style="color:#999;font-size: 14px;">奖励类型</span></Divider>
            <template v-for="(item,index) in platform">
              <div :key="index" v-if="item.id==importParam.platform_type">
                <RadioGroup v-model="importParam.reward_type" type="button" button-style="solid" @on-change="selchange('reward_type')">
                  <Radio v-for="(p,k) in item.rtype" :key="k" :label="p">{{p}}</Radio>
                </RadioGroup>
              </div>
            </template>
          </FormItem>
          <FormItem label="" v-if="isUpBtn && importParam.reward_type != ''">
            <Divider dashed><span style="color:#999;font-size: 14px;">上传数据</span></Divider>
            <upload ref="UpExcel" action="importFile" :maxSize="20240" :isBeforeVerifiy="true" :extraData="importParam" :formatArr="['xlsx','xls']" :imgName="importParam.file" @upSuccess="upSuccess" @upProgress="upProgress">
              <Button slot="btn_view" type="info"><Icon type="ios-add" size="20"></Icon>点击上传</Button>
            </upload>
          </FormItem>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/components/public/upload";
export default {
  name: "Import",
  components: {upload},
  data() {
    return {
      loading:true,
      isShowPercent:false,
      importParam:{
        platform_type:0,
        settlement_type:1,
        reward_type:'',
        file:''
      },
      platform:[],
      ruleValid: {
        reward_type: [
          { required: true,message: '*请选择奖励类型', trigger: 'change' }
        ],
      },
      isUpBtn:false,
      errorMsg:'',
      history:{
        platform_type:0,
        settlement_type:1,
        reward_type:'',
      }
    }
  },
  computed:{
    checkTypes(){
      return this.importParam.platform_type+this.importParam.settlement_type;
    }
  },
  watch:{
    checkTypes(){
      for (var i in this.platform){
        if(this.platform[i].id==this.importParam.platform_type){
          var has=0;
          for (var k in this.platform[i].rtype){
            if(this.importParam.reward_type==this.platform[i].rtype[k]) has=1;
          }
          this.importParam.reward_type = has?this.importParam.reward_type:'';
        }
      }
      this.$refs.importRef.validate();
    }
  },
  created() {
    this.history.platform_type=localStorage.getItem('platform_type');
    this.history.settlement_type=localStorage.getItem('settlement_type');
    this.history.reward_type=localStorage.getItem('reward_type');
    if(this.history.settlement_type==1 || this.history.settlement_type==2) this.importParam.settlement_type=parseInt(this.history.settlement_type);
    if(this.history.reward_type!=null) this.importParam.reward_type=this.history.reward_type;
    this.init();
  },
  methods:{
    init:function() {
      let _this = this;
      this.requestApi('/adm/importInit', {}).then(function (res) {
        _this.loading = false;
        _this.platform = res.platform;
        _this.importParam.platform_type=res.platform[0].id;
        if(null!=_this.history.platform_type){
          for (var i in res.platform){
            if(_this.history.platform_type==res.platform[i].id) _this.importParam.platform_type=res.platform[i].id;
          }
        }
        if(!res.status){
          _this.errorMsg = res.msg;
          _this.$Notice.warning({
            title: res.msg
          });
        }else{
          _this.isUpBtn = true;
          _this.errorMsg = '';
        }
        _this.$nextTick(function(){
          this.$refs.importRef.validate();
        })
      })
    },
    upProgress:function(e){
      if(e.percent == -1) this.isShowPercent = false;
      else this.isShowPercent = true;
    },
    upSuccess:function(e){
      this.$Message.success(e.response.message);
    },
    selchange:function (key){
      localStorage.setItem(key,this.importParam[key]);
      this.history[key]=this.importParam[key];
      this.$refs.UpExcel.UpExtraData(this.importParam);
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/css/import/import.less";
</style>